const getText = () => `<?xml version="1.0"?>
<container
 version="1.0"
 xmlns="urn:oasis:names:tc:opendocument:xmlns:container"
>
<rootfiles>
<rootfile
 full-path="OEBPS/standard.opf"
 media-type="application/oebps-package+xml"
/>
</rootfiles>
</container>`

export default getText