const getText = () =>`@charset "UTF-8";

html,
body {
    margin:    0;
    padding:   0;
    font-size: 0;
}
svg {
    margin:    0;
    padding:   0;
}
`

export default getText