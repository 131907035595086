const iconString = `<svg aria-hidden="true" style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<defs>
<symbol id="icon-price-tag" viewBox="0 0 20 20"><path d="M19.388 0.405c-0.111-0.314-0.454-0.48-0.769-0.371s-0.481 0.455-0.372 0.77c0.929 2.67-0.915 4.664-2.321 5.732l-0.568-0.814c-0.191-0.273-0.618-0.5-0.95-0.504l-3.188 0.014c-0.332-0.006-0.825 0.146-1.097 0.338l-9.394 6.587c-0.455 0.32-0.565 0.947-0.247 1.404l4.269 6.108c0.32 0.455 0.831 0.4 1.287 0.082l9.394-6.588c0.27-0.191 0.582-0.603 0.692-0.918l0.998-3.145c0.11-0.314 0.043-0.793-0.148-1.066l-0.346-0.496c1.888-1.447 3.848-4.004 2.76-7.133zM15.017 9.763c-0.727 0.51-1.731 0.332-2.24-0.396-0.511-0.73-0.333-1.734 0.395-2.246 0.578-0.405 1.328-0.376 1.868 0.017-0.272 0.164-0.459 0.26-0.494 0.275-0.301 0.143-0.43 0.504-0.288 0.805 0.104 0.219 0.321 0.348 0.547 0.348 0.086 0 0.174-0.020 0.257-0.059 0.194-0.092 0.402-0.201 0.619-0.33 0.097 0.594-0.139 1.217-0.664 1.586z"></path></symbol>
<symbol id="icon-heart" viewBox="0 0 20 20"><path d="M17.19 4.155c-1.672-1.534-4.383-1.534-6.055 0l-1.135 1.042-1.136-1.042c-1.672-1.534-4.382-1.534-6.054 0-1.881 1.727-1.881 4.52 0 6.246l7.19 6.599 7.19-6.599c1.88-1.726 1.88-4.52 0-6.246z"></path></symbol>
<symbol id="icon-star" viewBox="0 0 20 20"><path d="M10 1.3l2.388 6.722h6.412l-5.232 3.948 1.871 6.928-5.439-4.154-5.438 4.154 1.87-6.928-5.233-3.948h6.412l2.389-6.722z"></path></symbol>
<symbol id="icon-save" viewBox="0 0 20 20"><path d="M15.173 2h-11.173c-1.101 0-2 0.9-2 2v12c0 1.1 0.899 2 2 2h12c1.101 0 2-0.9 2-2v-10.873l-2.827-3.127zM14 8c0 0.549-0.45 1-1 1h-6c-0.55 0-1-0.451-1-1v-5h8v5zM13 4h-2v4h2v-4z"></path></symbol>
<symbol id="icon-retweet" viewBox="0 0 20 20"><path d="M5 13v-5h2l-3.5-4-3.5 4h2v6c0 1.104 0.895 2 2 2h9.482l-2.638-3h-5.844zM9.156 7l-2.638-3h9.482c1.104 0 2 0.897 2 2v6h2l-3.5 4-3.5-4h2v-5h-5.844z"></path></symbol>
<symbol id="icon-notification" viewBox="0 0 20 20"><path d="M15 8.38v8.62h-12v-12h8.62c-0.073-0.322-0.12-0.655-0.12-1s0.047-0.678 0.12-1h-8.62c-1.102 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-8.62c-0.322 0.073-0.655 0.12-1 0.12s-0.678-0.047-1-0.12zM16 1c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z"></path></symbol>
<symbol id="icon-chevron-down" viewBox="0 0 20 20"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></symbol>
<symbol id="icon-chevron-left" viewBox="0 0 20 20"><path d="M12.452 4.516c0.446 0.436 0.481 1.043 0 1.576l-3.747 3.908 3.747 3.908c0.481 0.533 0.446 1.141 0 1.574-0.445 0.436-1.197 0.408-1.615 0-0.418-0.406-4.502-4.695-4.502-4.695-0.223-0.217-0.335-0.502-0.335-0.787s0.112-0.57 0.335-0.789c0 0 4.084-4.287 4.502-4.695s1.17-0.436 1.615 0z"></path></symbol>
<symbol id="icon-chevron-right" viewBox="0 0 20 20"><path d="M9.163 4.516c0.418 0.408 4.502 4.695 4.502 4.695 0.223 0.219 0.335 0.504 0.335 0.789s-0.112 0.57-0.335 0.787c0 0-4.084 4.289-4.502 4.695-0.418 0.408-1.17 0.436-1.615 0-0.446-0.434-0.481-1.041 0-1.574l3.747-3.908-3.747-3.908c-0.481-0.533-0.446-1.141 0-1.576s1.197-0.409 1.615 0z"></path></symbol>
<symbol id="icon-chevron-up" viewBox="0 0 20 20"><path d="M15.484 12.452c-0.436 0.446-1.043 0.481-1.576 0l-3.908-3.747-3.908 3.747c-0.533 0.481-1.141 0.446-1.574 0-0.436-0.445-0.408-1.197 0-1.615 0.406-0.418 4.695-4.502 4.695-4.502 0.217-0.223 0.502-0.335 0.787-0.335s0.57 0.112 0.789 0.335c0 0 4.287 4.084 4.695 4.502s0.436 1.17 0 1.615z"></path></symbol>
<symbol id="icon-minus" viewBox="0 0 20 20"><path d="M16 10c0 0.553-0.048 1-0.601 1h-10.798c-0.552 0-0.601-0.447-0.601-1s0.049-1 0.601-1h10.799c0.552 0 0.6 0.447 0.6 1z"></path></symbol>
<symbol id="icon-plus" viewBox="0 0 20 20"><path d="M16 10c0 0.553-0.048 1-0.601 1h-4.399v4.399c0 0.552-0.447 0.601-1 0.601s-1-0.049-1-0.601v-4.399h-4.399c-0.552 0-0.601-0.447-0.601-1s0.049-1 0.601-1h4.399v-4.399c0-0.553 0.447-0.601 1-0.601s1 0.048 1 0.601v4.399h4.399c0.553 0 0.601 0.447 0.601 1z"></path></symbol>
<symbol id="icon-tools" viewBox="0 0 20 20"><path d="M3.135 6.89c0.933-0.725 1.707-0.225 2.74 0.971 0.116 0.135 0.272-0.023 0.361-0.1 0.088-0.078 1.451-1.305 1.518-1.361 0.066-0.059 0.146-0.169 0.041-0.292-0.107-0.123-0.494-0.625-0.743-0.951-1.808-2.365 4.946-3.969 3.909-3.994-0.528-0.014-2.646-0.039-2.963-0.004-1.283 0.135-2.894 1.334-3.705 1.893-1.061 0.726-1.457 1.152-1.522 1.211-0.3 0.262-0.048 0.867-0.592 1.344-0.575 0.503-0.934 0.122-1.267 0.414-0.165 0.146-0.627 0.492-0.759 0.607-0.133 0.117-0.157 0.314-0.021 0.471 0 0 1.264 1.396 1.37 1.52 0.105 0.122 0.391 0.228 0.567 0.071 0.177-0.156 0.632-0.553 0.708-0.623 0.078-0.066-0.050-0.861 0.358-1.177zM8.843 7.407c-0.12-0.139-0.269-0.143-0.397-0.029l-1.434 1.252c-0.113 0.1-0.129 0.283-0.027 0.4l8.294 9.439c0.194 0.223 0.53 0.246 0.751 0.053l0.97-0.813c0.222-0.195 0.245-0.533 0.052-0.758l-8.209-9.544zM19.902 3.39c-0.074-0.494-0.33-0.391-0.463-0.182-0.133 0.211-0.721 1.102-0.963 1.506-0.24 0.4-0.832 1.191-1.934 0.41-1.148-0.811-0.749-1.377-0.549-1.758 0.201-0.383 0.818-1.457 0.907-1.59 0.089-0.135-0.015-0.527-0.371-0.363s-2.523 1.025-2.823 2.26c-0.307 1.256 0.257 2.379-0.85 3.494l-1.343 1.4 1.349 1.566 1.654-1.57c0.394-0.396 1.236-0.781 1.998-0.607 1.633 0.369 2.524-0.244 3.061-1.258 0.482-0.906 0.402-2.814 0.327-3.308zM2.739 17.053c-0.208 0.209-0.208 0.549 0 0.758l0.951 0.93c0.208 0.209 0.538 0.121 0.746-0.088l4.907-4.824-1.503-1.714-5.101 4.938z"></path></symbol>
<symbol id="icon-bookmark" viewBox="0 0 20 20"><path d="M14 2v17l-4-4-4 4v-17c0-0.553 0.585-1.020 1-1h6c0.689-0.020 1 0.447 1 1z"></path></symbol>
<symbol id="icon-bookmarks" viewBox="0 0 20 20"><path d="M15 0h-4c-0.553 0-1 0.447-1 1l0.023 0.222c1.102 0 2 0.897 2 2v11.359l0.977-1.181 3 3.6v-16c0-0.553-0.447-1-1-1zM9.023 3h-4.023c-0.553 0-1 0.447-1 1v16l3-3.6 3 3.6v-16c0-0.553-0.424-1-0.977-1z"></path></symbol>
<symbol id="icon-document" viewBox="0 0 20 20"><path d="M16 1h-12c-0.553 0-1 0.447-1 1v16c0 0.552 0.447 1 1 1h12c0.553 0 1-0.448 1-1v-16c0-0.552-0.447-1-1-1zM15 17h-10v-14h10v14z"></path></symbol>
<symbol id="icon-upload" viewBox="0 0 20 20"><path d="M8 12h4v-6h3l-5-5-5 5h3v6zM19.338 13.532c-0.21-0.224-1.611-1.723-2.011-2.114-0.265-0.259-0.644-0.418-1.042-0.418h-1.757l3.064 2.994h-3.544c-0.102 0-0.194 0.052-0.24 0.133l-0.816 1.873h-5.984l-0.816-1.873c-0.046-0.081-0.139-0.133-0.24-0.133h-3.544l3.063-2.994h-1.756c-0.397 0-0.776 0.159-1.042 0.418-0.4 0.392-1.801 1.891-2.011 2.114-0.489 0.521-0.758 0.936-0.63 1.449l0.561 3.074c0.128 0.514 0.691 0.936 1.252 0.936h16.312c0.561 0 1.124-0.422 1.252-0.936l0.561-3.074c0.126-0.513-0.142-0.928-0.632-1.449z"></path></symbol>
<symbol id="icon-cross" viewBox="0 0 20 20"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></symbol>
<symbol id="icon-list" viewBox="0 0 20 20"><path d="M14.4 9h-5.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1h5.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1zM16.4 14h-7.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1h7.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1zM8.6 6h7.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1h-7.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1zM5.4 9h-1.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1h1.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1zM5.4 14h-1.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1h1.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1zM5.4 4h-1.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1h1.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1z"></path></symbol>
<symbol id="icon-menu" viewBox="0 0 20 20"><path d="M16.4 9h-12.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1h12.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1zM16.4 13h-12.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1h12.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1zM3.6 7h12.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1h-12.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1z"></path></symbol>
<symbol id="icon-ruler" viewBox="0 0 20 20"><path d="M14.249 0.438l-13.811 13.813c-0.584 0.584-0.584 1.538 0.002 2.124l3.185 3.187c0.584 0.584 1.541 0.586 2.124 0.002l13.813-13.813c0.584-0.585 0.584-1.541 0-2.125l-3.186-3.188c-0.587-0.586-1.542-0.583-2.127 0zM3.929 15.312l-0.759 0.759-1.896-1.897 0.759-0.759 1.896 1.897zM6.965 15.312l-0.759 0.759-3.415-3.415 0.759-0.76 3.415 3.416zM6.965 12.276l-0.759 0.759-1.898-1.896 0.76-0.76 1.897 1.897zM8.483 10.758l-0.759 0.759-1.896-1.896 0.759-0.76 1.896 1.897zM11.518 10.758l-0.759 0.759-3.414-3.414 0.759-0.759 3.414 3.414zM11.518 7.723l-0.759 0.759-1.896-1.896 0.759-0.759 1.896 1.896zM13.036 6.206l-0.759 0.759-1.897-1.897 0.759-0.759 1.897 1.897zM16.072 6.206l-0.76 0.759-3.414-3.415 0.759-0.76 3.415 3.416zM16.071 3.171l-0.759 0.759-1.896-1.898 0.759-0.758 1.896 1.897z"></path></symbol>
<symbol id="icon-scissors" viewBox="0 0 20 20"><path d="M8.38 5.59c0-2.038-1.652-3.69-3.69-3.69s-3.69 1.652-3.69 3.69c0 2.038 1.652 3.69 3.69 3.69 0.96 0 1.826-0.376 2.483-0.976l1.827 1.687 0.012 0.009-0.004 0.003-1.836 1.693c-0.656-0.6-1.522-0.976-2.482-0.976-2.038 0-3.69 1.652-3.69 3.69s1.652 3.69 3.69 3.69 3.69-1.652 3.69-3.69c0-0.297-0.044-0.582-0.111-0.858l2.844-1.991 4.127 3.065c2.212 1.549 3.76-0.663 3.76-0.663l-10.731-7.515c0.066-0.276 0.111-0.561 0.111-0.858zM4.69 7.39c-0.994 0-1.8-0.806-1.8-1.8s0.806-1.8 1.8-1.8 1.8 0.806 1.8 1.8-0.806 1.8-1.8 1.8zM4.69 16.21c-0.994 0-1.8-0.806-1.8-1.8s0.806-1.8 1.8-1.8 1.8 0.806 1.8 1.8-0.806 1.8-1.8 1.8zM19 6.038c0 0-1.548-2.212-3.76-0.663l-3.205 2.235 2.354 1.648 4.611-3.22z"></path></symbol>
<symbol id="icon-swap" viewBox="0 0 20 20"><path d="M14 5h-10v-2l-4 3.5 4 3.5v-2h10v-3zM20 13.5l-4-3.5v2h-10v3h10v2l4-3.5z"></path></symbol>
<symbol id="icon-install" viewBox="0 0 20 20"><path d="M19.059 10.898l-3.171-7.927c-0.234-0.587-0.802-0.971-1.434-0.971h-2.434l0.38 4.065h2.7l-5.1 4.228-5.1-4.228h2.7l0.38-4.065h-2.434c-0.632 0-1.2 0.384-1.434 0.971l-3.171 7.927c-0.288 0.721-0.373 1.507-0.246 2.272l0.59 3.539c0.124 0.745 0.768 1.291 1.523 1.291h14.383c0.755 0 1.399-0.546 1.523-1.291l0.59-3.539c0.129-0.765 0.044-1.551-0.245-2.272zM16.959 15.245c-0.072 0.436-0.449 0.755-0.891 0.755h-12.136c-0.442 0-0.819-0.319-0.891-0.755l-0.365-2.193c-0.093-0.551 0.332-1.052 0.891-1.052h12.867c0.558 0 0.983 0.501 0.891 1.052l-0.366 2.193z"></path></symbol>
<symbol id="icon-book" viewBox="0 0 20 20"><path d="M17 5.95v10.351c0 0.522-0.452 0.771-1 1.16-0.44 0.313-1-0.075-1-0.587 0 0 0-9.905 0-10.114 0-0.211-0.074-0.412-0.314-0.535s-7.738-4.065-7.738-4.065c-0.121-0.045-0.649-0.378-1.353-0.016-0.669 0.344-1.033 0.718-1.126 0.894l8.18 4.482c0.217 0.114 0.351 0.29 0.351 0.516v10.802c0 0.23-0.142 0.476-0.369 0.585-0.104 0.052-0.219 0.077-0.333 0.077-0.135 0-0.271-0.033-0.386-0.104-0.215-0.131-7.774-4.766-8.273-5.067-0.24-0.144-0.521-0.439-0.527-0.658l-0.112-10.286c0-0.198-0.023-0.547 0.289-1.032 0.697-1.084 3.129-2.317 4.36-1.678l8.999 4.555c0.217 0.112 0.352 0.336 0.352 0.72z"></path></symbol>
<symbol id="icon-cycle" viewBox="0 0 20 20"><path d="M5.516 14.224c-2.262-2.432-2.222-6.244 0.128-8.611 0.962-0.969 2.164-1.547 3.414-1.736l-0.069-2.077c-1.755 0.213-3.452 0.996-4.797 2.351-3.149 3.17-3.187 8.289-0.123 11.531l-1.741 1.752 5.51 0.301-0.015-5.834-2.307 2.323zM12.163 2.265l0.015 5.834 2.307-2.322c2.262 2.434 2.222 6.246-0.128 8.611-0.961 0.969-2.164 1.547-3.414 1.736l0.069 2.076c1.755-0.213 3.452-0.996 4.798-2.35 3.148-3.172 3.186-8.291 0.122-11.531l1.741-1.754-5.51-0.3z"></path></symbol>
<symbol id="icon-tag" viewBox="0 0 20 20"><path d="M18.662 5.521l-13.425 13.479 0.707-4.967-4.945 0.709 13.425-13.479c0.391-0.392 1.133-0.308 1.412 0l2.826 2.839c0.5 0.473 0.391 1.026 0 1.419z"></path></symbol>
<symbol id="icon-rocket" viewBox="0 0 640 640"><path d="M381.856 418.208c0 0 225.888-163.008 200.832-349.568-0.544-4.064-1.888-6.816-3.584-8.576-1.728-1.76-4.384-3.136-8.416-3.68-182.304-25.632-341.568 205.504-341.568 205.504-138.176-16.544-128.128 11.008-191.168 162.432-12.064 28.864 7.488 38.816 28.928 30.688 21.44-8.064 68.736-25.952 68.736-25.952l82.88 84.736c0 0-17.472 48.448-25.376 70.368-7.936 21.952 1.76 41.952 30.016 29.632 147.968-64.512 174.912-54.208 158.72-195.584zM414.144 228.896c-19.136-19.616-19.136-51.328 0-70.944 19.136-19.552 50.144-19.552 69.312 0 19.136 19.552 19.136 51.296 0 70.944-19.168 19.552-50.208 19.552-69.312 0z"></path></symbol>
</defs>
</svg>`

document.body.insertAdjacentHTML('afterbegin', iconString)

type IconProps = ({ name: string; className?: string })

const Icon = function(props: IconProps) {
  return (
    <svg className={"icon " + (props.className || '')}>
      <use xlinkHref={'#icon-' + props.name}></use>
    </svg>
  )
}

export default Icon