const getText = () => `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml" xmlns:epub="http://www.idpf.org/2007/ops" xml:lang="ja">
<head>
<meta charset="UTF-8"></meta>
<title>Navigation</title>
</head>
<body>
<nav epub:type="toc" id="toc">
<h1>Navigation</h1>
<ol>
<!-- navigation-list -->
</ol>
</nav>
<nav epub:type="landmarks">
<ol>
<li><a epub:type="bodymatter" href="text/p_cover.xhtml">Start of Content</a></li>
</ol>
</nav>
</body>
</html>
`

export default getText